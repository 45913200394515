<template>
  <div class="card-header justify-content-between head-sort">
    <custom-select-2
      v-model="searchParams.searchStatus"
      name="status"
      label="Статус:"
      :options="['Не выбрано', 'Успех', 'Ошибка']"
    />
    <button @click="clearAllSearch" class="btn btn-w reset-btn">
      Очистить
    </button>
  </div>
  <div class="card-body">
    <div v-if="excelStaff">
      <ag-grid-vue
        :columnDefs="columnDefs.value"
        :rowData="rowData"
        :rowHeight="70"
        :headerHeight="90"
        :enableCellTextSelection="true"
        :ensureDomOrder="true"
        :pagination="true"
        :paginationPageSize="countOnPage"
        :defaultColDef="defaultColDef"
        :suppressRowHoverHighlight="true"
        :suppressPaginationPanel="true"
        :suppressRowClickSelection="true"
        :suppressRowTransform="true"
        style="width: 100%"
        domLayout="autoHeight"
        @grid-ready="onGridReady"
      ></ag-grid-vue>

      <v-pagination
        v-if="gridApi"
        v-model="page"
        @update:modelValue="changePage"
        :pages="gridApi.paginationGetTotalPages()"
        :range-size="1"
        active-color="transparent"
      />

      <div
        v-if="gridApi && gridApi.paginationGetTotalPages()"
        class="pagination-info"
      >
        <p>
          Показаны строки с {{ countOnPage * (page - 1) + 1 }} по
          {{ gridApi.paginationProxy.bottomDisplayedRowIndex + 1 }} из
          {{ gridApi.paginationGetRowCount() }}
        </p>
        <p>
          Страница {{ gridApi.paginationGetCurrentPage() + 1 }} из
          {{ gridApi.paginationGetTotalPages() }}
        </p>
      </div>
      <div class="pagination-info" v-else>Нет результатов</div>
    </div>
    <div v-else class="table-preloader">
      <preloader />
    </div>
  </div>
</template>

<script>
import {
  computed,
  onBeforeUnmount,
  reactive,
  ref,
  watch,
} from "@vue/runtime-core";
import VPagination from "@hennge/vue3-pagination";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import { AgGridVue } from "ag-grid-vue3";
import "ag-grid-community/dist/styles/ag-grid.css";
import ExcelStatusTextRenderer from "@/components/Tables/CellRenderers/ExcelStatusTextRenderer.vue";
import Preloader from "@/components/Technical/Preloader.vue";

export default {
  name: "group-passings-table",
  components: {
    CustomSelect2,
    VPagination,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    ExcelStatusTextRenderer,
    Preloader,
  },
  setup() {
    const store = useStore(),
      route = useRoute(),
      gridApi = ref(null),
      columnApi = ref(null);

    const onGridReady = (params) => {
      gridApi.value = params.api;
      columnApi.value = params.columnApi;
    };
    const countOnPage = ref(15),
      page = ref(1),
      searchParams = reactive({
        searchStatus: "Не выбрано",
      }),
      isPreloader = ref(false);
    const excelStaff = computed(() => store.state.group.excelStaff),
      rowData = computed(() => {
        return excelStaff.value.map((staff) => {
          return {
            name: staff.name,
            email: staff.email,
            status: staff.status ? "Успех" : "Ошибка",
            error: staff.error ? staff.error : null,
          };
        });
      });

    const columnDefs = reactive({
      value: [
        {
          field: "name",
          headerName: "Имя",
          wrapText: true,
          minWidth: 255,
        },
        {
          field: "email",
          headerName: "Email",
          wrapText: true,
          minWidth: 255,
        },
        {
          field: "status",
          headerName: "Статус",
          wrapText: true,
          minWidth: 140,
          cellRenderer: "ExcelStatusTextRenderer",
        },
        {
          field: "error",
          headerName: "Ошибка",
          wrapText: true,
          minWidth: 220,
        },
      ],
    });
    const defaultColDef = {
      suppressMovable: true,
      flex: 1,
      autoHeight: true,
    };

    const clearAllSearch = () => {
      searchParams.searchStatus = "Не выбрано";
    };

    const changePage = (page) => {
      gridApi.value.paginationGoToPage(page - 1);
    };

    watch(searchParams, () => {
      if (searchParams.searchStatus !== "Не выбрано") {
        gridApi.value.setQuickFilter(`${searchParams.searchStatus} `);
      } else {
        gridApi.value.setQuickFilter(null);
      }
      page.value = 1;
    });

    watch(countOnPage, () => {
      gridApi.value.paginationSetPageSize(countOnPage.value);
      page.value = 1;
    });

    onBeforeUnmount(() => {
      store.commit("group/setExcelStaff", []);
    });

    return {
      route,
      countOnPage,
      isPreloader,
      searchParams,
      page,
      clearAllSearch,
      changePage,
      excelStaff,

      columnDefs,
      rowData,
      defaultColDef,
      gridApi,
      onGridReady,
      columnApi,
    };
  },
};
</script>

<style lang="scss" scoped>
.head-sort {
  .form-group {
    min-width: 50%;
  }
}
</style>
