<template>
  <div class="view-container">
    <div class="card staff-table">
      <div class="card-header card-header-divider">
        <div class="card-title">Полученные сотрудники</div>
      </div>
      <excel-staff-table />
    </div>
  </div>
</template>

<script>
import ExcelStaffTable from "../../feature/users/ExcelStaffTable.vue";
export default {
  components: { ExcelStaffTable },
  name: "excel-staff-page",
};
</script>

<style>
</style>